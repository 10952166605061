<template>
    <div className="page-wrapper">
      <div
        style="
          background-size: cover;
          box-shadow: inset 0 0 0 2000px rgba(20, 20, 20, 0.3);
        "
        id="theme-main-banner"
        class="banner-one"
      >
        <div
          v-for="(item, i) in informacoes.slide"
          :key="i"
          data-src="images/home/slide-1.jpg"
        >
          <div
            class="camera_caption"
            style="
              background-size: cover;
              box-shadow: inset 0 0 0 2000px rgba(20, 20, 20, 0.3);
            "
          >
            <div class="container">
              <p
                class="wow fadeInUp animated"
                style="color: aliceblue !important"
              >
                {{ item.titulo }}
              </p>
              <h1
                class="wow fadeInUp animated"
                style="color: aliceblue"
                data-wow-delay="0.2s"
              >
                {{ item.texto1 }}<br />
              </h1>
              <h2
                class="wow fadeInUp animated"
                style="margin-bottom: 20px; color: aliceblue"
                data-wow-delay="0.2s"
              >
                {{ item.texto2 }}
              </h2>
              <a
                v-if="item.botao"
                :target="item.target ? '_blank' : ''"
                :href="item.btn_link"
                class="theme-button-one wow fadeInUp animated"
                data-wow-delay="0.39s"
                >{{ item.btn_title }}</a
              >
            </div>
          </div>
        </div>
      </div>
  
      <div class="callout-banner section-spacing">
        <div class="container clearfix">
          <h3 class="title">Soluções de energias confiáveis</h3>
          <p></p>
          <a
            href="#"
            v-scroll-to="{ el: '.contato-block', duration: 1700 }"
            class="theme-button-one"
            >ENTRE EM CONTATO</a
          >
        </div>
      </div>
  
      <div class="sobre-block no-bg section-spacing">
        <div class="overlay">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-12 text order-lg-last">
                <div class="theme-title-one">
                  <h2>{{ informacoes.blocos[0].title }}</h2>
                </div>
                <p>{{ informacoes.blocos[0].txt }}</p>
                <a
                  target="_blank"
                  v-if="informacoes.blocos[0].link"
                  :href="informacoes.blocos[0].link"
                  class="theme-button-one"
                  >VER MAIS</a
                >
                <a
                  v-else
                  href="#"
                  v-scroll-to="{ el: '.contato-block', duration: 1700 }"
                  class="theme-button-one"
                  >ENTRAR EM CONTATO</a
                >
              </div>
              <div class="col-lg-6 col-12">
                <img
                  :src="informacoes.blocos[0].img"
                  :alt="informacoes.blocos[0].title"
                  class="left-img cropped1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div v-if="disabled" class="our-solution section-spacing">
        <div class="container">
          <div class="theme-title-one">
            <h2>NOSSAS SOLUÇÕES</h2>
            <p>Sinta-se a vontade para entrar em contato e retirar sua dúvida!</p>
          </div>
          <div class="wrapper">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="single-solution-block">
                  <img src="images/icon/8.png" alt="" class="icon" />
                  <h5>Locação</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam sed bibendum velit. Fusce et justo lacus.
                  </p>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="single-solution-block">
                  <img src="images/icon/8.png" alt="" class="icon" />
                  <h5>Equipamentos</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam sed bibendum velit. Fusce et justo lacus.
                  </p>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="single-solution-block">
                  <img src="images/icon/8.png" alt="" class="icon" />
                  <h5>Baterias</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam sed bibendum velit. Fusce et justo lacus.
                  </p>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="single-solution-block">
                  <img src="images/icon/8.png" alt="" class="icon" />
                  <h5>Painéis</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam sed bibendum velit. Fusce et justo lacus.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="sobre-block section-spacing">
        <div class="overlay">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-12 text order-lg-last">
                <img
                  :src="informacoes.blocos[1].img"
                  :alt="informacoes.blocos[1].title"
                  class="left-img cropped2"
                />
              </div>
  
              <div class="col-lg-6 col-12 text">
                <div class="theme-title-one">
                  <h2>{{ informacoes.blocos[1].title }}</h2>
                </div>
                <p>{{ informacoes.blocos[1].txt }}</p>
                <a
                  v-if="informacoes.blocos[1].link"
                  :href="informacoes.blocos[1].link"
                  target="_blank"
                  class="button2 theme-button-one"
                  >ENTRAR EM CONTATO</a
                >
                <a
                  v-else
                  href="#"
                  v-scroll-to="{ el: '.contato-block', duration: 1700 }"
                  class="button2 theme-button-one"
                  >ENTRAR EM CONTATO</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="sobre-block no-bg section-spacing" id="sb2">
        <div class="overlay">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-12 text order-lg-last">
                <div class="theme-title-one">
                  <h2>{{ informacoes.blocos[2].title }}</h2>
                </div>
                <p>{{ informacoes.blocos[2].txt }}</p>
                <a
                  v-if="informacoes.blocos[2].link"
                  :href="informacoes.blocos[2].link"
                  target="_blank"
                  class="theme-button-one"
                  >VER MAIS</a
                >
                <a
                  v-else
                  href="#"
                  v-scroll-to="{ el: '.contato-block', duration: 1700 }"
                  class="theme-button-one"
                  >ENTRAR EM CONTATO</a
                >
              </div>
              <div class="col-lg-6 col-12 order-lg-end">
                <img
                  :src="informacoes.blocos[2].img"
                  :alt="informacoes.blocos[2].title"
                  class="left-img cropped2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="equipamentos-block section-spacing">
        <div class="container">
          <div class="theme-title-one">
            <h2>Equipamentos</h2>
            <p>RELAÇÃO DE EQUIPAMENTOS PARA COMERCIALIZAÇÃO E LOCAÇÃO</p>
          </div>
          <div class="wrapper">
            <div class="row">
              <div
                class="col-lg-4 col-sm-6 col-12"
                v-for="(item, i) in Equipamentos"
                :key="i"
              >
                <div class="single-case-block">
                  <img v-bind:src="item.img" v-bind:alt="item.nome" />
                  <div class="hover-content">
                    <div class="text clearfix">
                      <div class="float-left">
                        <h5 style="color: aliceblue">{{ item.nome }}</h5>
                        <p>{{ item.desc }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="view-all"><a href="#" class="theme-button-one">VER MAIS</a></div> -->
        </div>
      </div>
  
      <div class="equipamentos-block section-spacing">
        <div class="container">
          <div class="theme-title-one">
            <h2>Baterias</h2>
            <p>
              Realizamos substituições de banco de baterias para nobreaks,
              geradores e retificadores. Trabalhamos com baterias seladas VRLA de
              válvula controlada e baterias estacionárias.
            </p>
          </div>
          <div class="wrapper">
            <div class="row">
              <div
                class="col-lg-4 col-sm-6 col-12"
                v-for="(item, i) in Baterias"
                :key="i"
              >
                <div class="single-case-block">
                  <img v-bind:src="item.img" v-bind:alt="item.nome" />
                  <div class="hover-content">
                    <div class="text clearfix">
                      <div class="float-left">
                        <h5 style="color: aliceblue">{{ item.nome }}</h5>
                        <p>{{ item.desc }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="view-all"><a href="#" class="theme-button-one">VER MAIS</a></div> -->
        </div>
      </div>
  
      <div class="contato-block">
        <div class="overlay">
          <div class="container">
            <div class="theme-title-one">
              <h2>ENTRE EM CONTATO</h2>
            </div>
  
            <div class="wrapper row no-gutters">
              <div class="col-xl-6 col-lg-7 order-last">
                <div class="faq-content">
                  <div class="form-wrapper">
                    <form
                    @submit.prevent="submitForm"
                      enctype="multipart/form-data"
                      class="theme-form-one"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <input
                            v-model="formData.nome"
                            type="text"
                            placeholder="Nome *"
                            name="name"
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="phone"
                            v-model="formData.telefone"
                            :onchange="mask()"
                            placeholder="Celular *"
                            name="phone"
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="email"
                            v-model="formData.email"
                            placeholder="Email *"
                            name="email"
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <select
                            class="form-control"
                            style="margin-bottom: 25px"
                            name="onde"
                            required
                            id="source"
                            v-model="formData.source"
                          >
                            <option>Onde nos encontrou?</option>
  
                            <option value="Google">Google</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Anúncio">Anúncio</option>
                            <option value="Outros">Outros</option>
                          </select>
                        </div>
                        <div class="col-12">
                          <textarea
                            placeholder="Mensagem"
                            v-model="formData.message"
                            
                            name="message"
                            required
                          ></textarea>
                        </div>
                      </div>
                  <p v-if="message" :class="messsageerror ? 'errorcss' : 'sucesscss'">{{ message }}</p>
                      <button type="submit" class="theme-button-one">
                        ENVIAR
                      </button>
                    </form>
                  </div>
                </div>
              </div>
  
              <div class="col-xl-6 col-lg-5 order-first">
                <!-- <div class="img-box">
                                      <a data-fancybox href="https://www.youtube.com/embed/video?rel=0&amshowinfo=0" class="play"><i class="fa fa-play" aria-hidden="true"></i></a>
                                  </div> -->
                <img
                  src="images/blocos/contato.jpg"
                  alt="contato"
                  class="left-img cropped3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="empresas-block bg-color">
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-sm-4 col-12">
              <h6>Marcas <br />Que trabalhamos</h6>
            </div>
            <div class="col-md-9 col-sm-8 col-12">
              <div class="partner-slider">
                <div v-for="(item, i) in marcas" :key="i" class="item">
                  <img :src="item.img" :alt="item.nome" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <style>
      .errorcss{
          color: red;
      }
        .sucesscss{
          color: green;
      }
    .cropped1 {
      width: 540px; /* width of container */
      height: 510px; /* height of container */
      object-fit: cover;
    }
    .cropped2 {
      width: 540px; /* width of container */
      height: 410px; /* height of container */
      object-fit: cover;
    }
    .cropped3 {
      width: 100%; /* width of container */
      height: 100%; /* height of container */
      max-height: 450px;
      object-fit: cover;
    }
  </style>
  <script>
    import axios from "axios";
  
    export default {
      name: "Home",
      components: {
        
      },
      methods: {
        async submitForm() {

    
      try {
        const formData = new FormData();
        formData.append("nome", this.formData.nome);
        formData.append("email", this.formData.email);
        formData.append("telefone", this.formData.telefone);
        formData.append("mensagem", this.formData.message);
        formData.append("source", this.formData.source);

        const response = await axios.post('https://cdn.jmlenergias.com/a.php', formData);

        if (response.data.success) {
                   this.messsageerror = false;
        } else {
           this.messsageerror  = true;
        }

          this.message = response.data.msg;
          
      } catch (error) {
        this.message = 'Ocorreu um erro ao enviar o email.';
        console.error(error);
      }
    },
         
        isNumber: function (evt) {
          evt = evt ? evt : window.event;
          var charCode = evt.which ? evt.which : evt.keyCode;
          if (
            charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            charCode !== 46
          ) {
            evt.preventDefault();
          } else {
            return true;
          }
        },
  
        mask() {
          var telefone = this.telefone.replace(/\D/g, "");
  
          var textoAjustado;
  
          if (telefone.length <= 6) {
            textoAjustado = telefone.replace(/(\d{2})/, function (regex, arg1) {
              return "(" + arg1 + ") ";
            });
          } else if (telefone.length < 11) {
            textoAjustado = telefone.replace(
              /(\d{2})(\d{4})/,
              function (regex, arg1, arg2) {
                return "(" + arg1 + ") " + arg2 + "-";
              }
            );
          } else {
            textoAjustado = telefone.replace(
              /(\d{2})(\d{5})(\d{4})/,
              function (regex, arg1, arg2, arg3) {
                return "(" + arg1 + ") " + arg2 + "-" + arg3;
              }
            );
          }
  
          this.telefone = textoAjustado;
        },
        showMatriz(show) {
          const el = document.body;
  
          this.matrizcurricular = show;
  
          if (show === false) {
            el.classList.remove("locked");
          } else {
            el.classList.add("locked");
          }
        },
        showPopup(item, show) {
          const el = document.body;
  
          this.Popup = show;
          console.log(item);
          if (show === false) {
            this.select = {
              img: "/resources/error.jpg",
              nome: "",
              descr: "",
              sub_title: "",
              descr_breve: "",
            };
  
            el.classList.remove("locked");
          } else {
            this.select = item;
            el.classList.add("locked");
          }
        },
        stringmax(text) {
          var length = 120;
  
          var string = text;
          var trimmedString =
            string.length > length
              ? string.substring(0, length - 3) + "..."
              : string;
  
          return trimmedString;
        },
      },
  
      data: function () {
        return {
          errors: [],
          success: false,
          errors1: [],
          success1: false,
          errors2: [],
          success2: false,
          email: "",
          disabled: false,
          nome: "",
          telefone: "",   
            message: "",   
            messsageerror: false,   
            
          formData: {
        name: '',
        email: '',
        message: '',
        source: 'Google', // Defina uma opção padrão se desejar
      },
          telefonew: "",
          Popup: false,
          select: {
            img: "/resources/error.jpg",
            nome: "",
            descr: "",
            sub_title: "",
            descr_breve: "",
          },
          Equipamentos: [
            {
              nome: "Equipamento Pequeno",
              img: "images/equipamentos/1.jpg",
              desc: "2 nobreaks",
            },
  
            {
              nome: "Equipamento Grande",
              img: "images/equipamentos/3.jpg",
              desc: "2 nobreaks",
            },
            {
              nome: "Equipamento Médio ",
              img: "images/equipamentos/4.jpeg",
              desc: "2 nobreaks",
            },
          ],
          Baterias: [
            {
              nome: "Banco de Baterias ",
              img: "images/baterias/1.jpg",
              desc: "Ellier",
            },
  
            {
              nome: "Banco de Baterias",
              img: "images/baterias/2.png",
              desc: "Estacionárias",
            },
            {
              nome: "Banco de Baterias",
              img: "images/baterias/3.jpg",
              desc: "VRLA",
            },
          ],
          informacoes: {
            blocos: [
              {
                id: 1,
                title: "SOBRE NÓS",
                txt: `A JML é uma empresa fundada recentemente em Guaratinguetá, interior de São Paulo. Atuamos na área de engenharia elétrica e nos especializamos em sistemas ininterruptos, qualidade e condicionamento de energia atuando em diversos setores como: Bancos, supermercados, hipermercados, Organizações militares e órgãos públicos, hospitais, industrias, laboratórios e comércio em geral.
   A JML foi fundada recentemente porém conta com a experiência de seus funcionários no ramo de manutenção e desenvolvimento de projetos elétricos a mais de 15 anos na indústria. Possui um corpo técnico especializado e que busca cada vez mais conquistar a confiança e o respeito de seus clientes.
   A visão da JML é fornecer produtos e mão de obra de manutenção com elevado grau de qualidade, sofisticação e avançada tecnologia para todo o vale do paraíba.`,
                img: "images/blocos/sobre.jpg",
                link: "",
                target: false,
                status: true,
              },
              {
                id: 2,
                title: "MANUTENÇÃO",
                txt: `A JML Sistemas de Energia, é uma empresa especializada em assistência técnica que atua no ramo de manutenção corretiva, preventiva e preditiva em nobreak's, estabilizadores, retificadores, geradores de energia e banco de baterias na indústria e comércio. Elaboração de projetos elétricos residenciais, industriais e laudo técnico com anotação de responsabilidade técnica.`,
                img: "images/blocos/manutencao.jpg",
                link: "",
                target: false,
                status: true,
              },
              {
                id: 3,
                title: "LOCAÇÃO",
                txt: `A JML dispõe de vários tipos de nobreaks para locação, trabalhamos com as melhores marcas do mercado para satisfazer as necessidades de nossos clientes. Possuímos máquinas de pequeno, médio e grande porte de acordo com a necessidade do cliente.`,
                img: "images/blocos/locacao.webp",
                link: "",
                target: false,
                status: false,
              },
              {
                id: 4,
  
                img: "images/blocos/contato.jpg",
                link: "",
                target: false,
                status: false,
              },
              {
                id: 5,
  
                img: "images/blocos/contato.jpg",
                link: "",
                target: false,
                status: false,
              },
            ],
            slide: [
              {
                img: "images/home/slide-1.jpg",
                type: 1,
                titulo: "Projetos de Cargas",
                texto1: "Levantamento de cargas de potência exigidas",
                texto2: "Dimensionamento da capacidade de carga de nobreak",
                botao: false,
                btn_title: "aa",
                btn_link: "",
                target: false,
              },
              {
                img: "images/home/slide-2.jpg",
                type: 1,
                titulo: "Manutenção de sistemas de energia",
                texto1: "Corretiva, Preventiva e Preditiva",
                texto2: "Análise de riscos",
                botao: false,
                btn_title: "",
                btn_link: "",
                target: false,
              },
              {
                img: "images/home/slide-3.jpg",
                type: 1,
                titulo: "Vendas e locação de nobreaks",
                texto1: "trabalhamos com nobreaks pequenos, médios e grandes",
                texto2: "Vendas e locações de equipamentos de todos os portes.",
                botao: false,
                btn_title: "",
                btn_link: "",
                target: false,
              },
            ],
          },
          marcas: [
            {
              nome: "APC",
              img: "images/empresas/apc.webp",
              url: "#",
            },
            {
              nome: "Intelbras",
              img: "images/empresas/intelbras.webp",
              url: "#",
            },
            {
              nome: "Legrand",
              img: "images/empresas/legrand.webp",
              url: "#",
            },
            {
              nome: "Multilaser",
              img: "images/empresas/multilaser.webp",
              url: "#",
            },
            {
              nome: "NHS",
              img: "images/empresas/nhs.webp",
              url: "#",
            },
            {
              nome: "Piller",
              img: "images/empresas/piller.webp", //
              url: "#",
            },
            {
              nome: "Schneider",
              img: "images/empresas/schneider.webp", //
              url: "#",
            },
            {
              nome: "Adelco",
              img: "images/empresas/adelco.webp", //
              url: "#",
            },
            {
              nome: "Socomec",
              img: "images/empresas/socomec.webp", //
              url: "#",
            },
            {
              nome: "Mitsubishi",
              img: "images/empresas/mitsubishi.webp", //
              url: "#",
            },
            {
              nome: "Engetron",
              img: "images/empresas/engetron.webp", //
              url: "#",
            },
          ],
        };
      },
      mounted() {
        let texto = this.informacoes.blocos[1].txt.substring(0, 300) + "...";
  
        document.getElementById("textsobre").textContent = texto;
      },
    };
  </script>
  
