<template>
  <div v-if="!manutencao">
<header className="header-two" >
    <div className="theme-menu-wrapper" >
      <div className="container" >
        <div className="bg-wrapper clearfix" >
          <div className="logo float-left " >
            <a href="/"><img :src="extras.logo" style="max-width: 230px; padding: 20px" :alt="extras.nome" /></a>
          </div>

          <div className="menu-wrapper float-left">
            <nav id="mega-menu-holder" className="clearfix">
              <ul className="clearfix">
                <li v-on:click="menumobile('.inicio-block')" className="active" ><a href="#" >Início</a></li>
                <li v-on:click="menumobile('.sobre-block')" ><a href="#">Sobre nós</a></li>
                <li v-on:click="menumobile('.locação-block')"><a href="#">Locação</a></li>
                <li v-on:click="menumobile('.equipamentos-block')" ><a href="#">Equipamentos</a></li>

                <li v-on:click="menumobile('.contato-block')" ><a href="#">Contato</a></li>
              </ul>
            </nav>
          </div>
          <div className="right-widget float-right">
            <ul className="social-icon">
              <li>
                <a target="_blank" :href="extras.facebook" v-if="extras.facebook"
                  ><i className="fa fa-facebook" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a target="_blank" :href="extras.instagram" v-if="extras.instagram"
                  ><i className="fa fa-instagram" aria-hidden="true"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>

    <router-view />

  <footer className="theme-footer-two">
    <div className="top-footer">
      <div className="container">
        <div className="row">

          <div className="col-lg-4 col-sm-4 col-12 logo-widget" >
            <h6 className="title">SOBRE</h6>
            <div className="logo">
              <a href="/"><img :src="extras.logo" style="max-width: 230px" :alt="extras.nome" /></a>
            </div>

            <p id='textsobre' style="margin-right: 20%">... </p><a style="font-size:13px; color: #cad2e5" href="#" v-scroll-to="{ el: '.sobre-block', duration: 1700 }">Ler Mais</a>
            <ul className="social-icon">
              <li>
                <a :href="extras.facebook" v-if="extras.facebook"
                  ><i className="fa fa-facebook" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a :href="extras.instagram" v-if="extras.instagram"
                  ><i className="fa fa-instagram" aria-hidden="true"></i
                ></a>
              </li>
            </ul>
          </div>


           <div className="col-lg-4 col-sm-4 col-12 contact-widget">
            <h6 className="title">DEPARTAMENTOS</h6>
            <ul>

               <li>
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                <a target="_blank" :href="'https://api.whatsapp.com/send?phone=55' + extras.cel2.replace(/[^0-9]/g, '')">{{extras.cel2_2}}</a>
              </li>
              <li>
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                <a target="_blank" :href="'https://api.whatsapp.com/send?phone=55' + extras.cel3.replace(/[^0-9]/g, '')">{{extras.cel3_2}}</a>
              </li>
              <li>
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                <a target="_blank" :href="'https://api.whatsapp.com/send?phone=55' + extras.cel4.replace(/[^0-9]/g, '')">{{extras.cel4_2}}</a>
              </li>
              <li>
                <i className="fa fa-phone" aria-hidden="true"></i>
                <a target="_blank" :href="'https://api.whatsapp.com/send?phone=55' + extras.cel5.replace(/[^0-9]/g, '')">{{extras.cel5_2}}</a>
              </li>
            </ul>
          </div>


          <div className="col-lg-4 col-sm-4 col-12 contact-widget">
            <h6 className="title">CONTATO</h6>
            <ul>
              <li>
                <i className="flaticon-direction-signs"></i>
                {{extras.endereco}}
              </li>
              <li>
                <i className="flaticon-multimedia-1"></i>
                <a :href="'mailto:' + extras.email">{{extras.email}}</a>
              </li>
              <li>
                <i className="fa fa-phone" aria-hidden="true"></i>
                <a target="_blank" :href="'https://api.whatsapp.com/send?phone=55' + extras.cel.replace(/[^0-9]/g, '')">{{extras.cel_2}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="bottom-footer">
      <div className="container">
        <p>&copy; Copyrights 2021{{extras.ano}}. {{extras.nome}} - {{extras.documento}}</p>
      </div>
    </div>
  </footer>

  <button className="scroll-top tran3s">
    <i className="fa fa-angle-up" aria-hidden="true"></i>
  </button>
  </div>

  <div v-else>

  </div>
</template>



<script>

export default {
  components: {},


  data: function () {
    return {
        manutencao: false,
        extras: {
          endereco: 'Rua Júlio Pacetti Filho 129, Jardim Santa Luzia - Guaratinguetá - SP',
          email: 'contato@jmlenergias.com.br',
          cel: '(12) 98802-8642',
          cel2: '(12) 98802-8642',
          cel3: '(12) 98802-8642',
          cel4: '(12) 98802-8642',
          cel5: '(12) 98802-8642',

           cel_2: '(12) 98802-8642 | Contato',
          cel2_2: '(12) 98802-8642 | Departamento de Engenharia ',
          cel3_2: '(12) 98802-8642 | Departamento de Projetos ',
          cel4_2: '(12) 98802-8642 | Assistência Técnica',
          cel5_2: '(12) 98802-8642 | Filial São Paulo',
          facebook: '',
          intagram: '',
          logo: 'images/logo/logo.png',
          documento: 'CNPJ: 45.086.596/0001-22',
          nome: 'JML ENGENHARIA',
          ano: '-',
        },
      topostyle: true,
    };
  },
  methods: {
    menumobile(element){

      var id = document.getElementById("mega-menu-holder");
      if(id.style.display === 'block'){
      id.style.display = id.style.display === 'none' ? '' : 'none';
      }


      this.$scrollTo(element, 1700)
    }
  },
    mounted(){

    let now = new Date
    let ano = now.getFullYear()

    if(ano > 2021){
      this.extras.ano = ' - ' + ano
    }else{
       this.extras.ano = ''
    }

  },
};
</script>
