import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import vueScrollto from 'vue-scrollto'



const app = createApp(App)

app.use(router)

app.use(vueScrollto, {
  container: "body",
  duration: 1000,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})



  app.mount('#app')
